<template>
  <!--begin::User Listing-->
  <ListingTemplate
    :customClass="'user-listing'"
    v-if="getPermission('user:view')"
  >
    <template v-slot:body>
      <ListingHeader>
        <template v-slot:content>
          <template v-if="selectedRows.length > 0">
            <v-col cols="4" class="pt-0">
              <h1 class="form-title d-flex margin-auto selected-rows-text">
                {{ totalSelected }} Selected
              </h1>
            </v-col>
            <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
              <template v-if="getPermission('user:update')">
                <v-menu
                  content-class="custom-menu-list"
                  bottom
                  left
                  offset-y
                  transition="slide-y-transition"
                  :close-on-click="true"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-grey-border custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(more, index) in userMoreAction">
                      <v-list-item
                        link
                        v-on:click="updateMoreAction(more.action)"
                        :key="index"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue">{{
                            more.icon
                          }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                          class="font-weight-500 font-size-14"
                          >{{ more.title }}</v-list-item-title
                        >
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </template>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                color="red lighten-1 white--text"
                v-on:click="clearSelections"
              >
                <v-icon dark left>mdi-close</v-icon>Clear Selections
              </v-btn>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="6" class="pt-0">
              <h1 class="form-title d-flex margin-auto">
                <v-select
                  :disabled="dataLoading"
                  :items="statusList"
                  v-model="status"
                  hide-details
                  item-color="cyan"
                  class="pt-0 mt-0 listing-status main-listing-status-filter"
                  item-text="text"
                  item-value="value"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="item.description"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip :color="item.color" :text-color="item.textcolor">
                        <template v-if="item.value == 'all'">{{
                          item.all_users
                        }}</template>
                        <template v-else>{{ item.user_status_count }}</template>
                      </v-chip>
                    </v-list-item-action>
                  </template>
                </v-select>
              </h1>
            </v-col>
            <v-col cols="6" class="pt-0 justify-flex-end d-flex margin-auto">
              <template v-if="getPermission('user:create')">
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-bold-button"
                  :to="getDefaultRoute('user.create')"
                  color="cyan white--text"
                >
                  <v-icon dark left>mdi-plus</v-icon> Create
                </v-btn>
              </template>
              <v-menu
                max-height="400"
                max-width="250"
                offset-y
                left
                :close-on-content-click="false"
                content-class="white-background"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    color="cyan white--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark>mdi-table-edit</v-icon>
                  </v-btn>
                </template>
                <draggable
                  tag="ul"
                  v-model="defaultColDefs"
                  class="draggable-group"
                  handle=".draggable-drag-icon"
                >
                  <template v-for="cols in defaultColDefs">
                    <li
                      class="draggable-group-item"
                      :key="cols.field"
                      v-if="!cols.checkbox"
                    >
                      <v-checkbox
                        dense
                        v-model="defaultColShow"
                        v-bind:value="cols.field"
                        :label="cols.headerName"
                        :disabled="cols.fixed || dataLoading"
                        color="cyan"
                        hide-details
                        class="mt-0 mb-0"
                      ></v-checkbox>
                      <v-icon class="draggable-drag-icon" right color="cyan"
                        >mdi-drag</v-icon
                      >
                    </li>
                  </template>
                </draggable>
              </v-menu>
            </v-col>
          </template>
        </template>
      </ListingHeader>
      <ListingSearchCriteria
        v-if="searchEnabled"
        pageModule="User"
        :basicSearchFields="['display_name']"
        :allowedSearchFields="searchableArray"
      ></ListingSearchCriteria>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow user-listing-height"
        style="position: relative"
      >
        <ListingTable
          :columnCount="columnLength()"
          :dataLoading="dataLoading"
          :rowData="rowData"
        >
          <template v-slot:thead>
            <thead>
              <tr>
                <template v-for="cols in defaultColDefs">
                  <th
                    v-if="cols.visible"
                    :key="cols.id"
                    class="simple-table-th"
                    :class="{
                      'checkbox-width-limit': cols.checkbox,
                      'pointer-events-none': lodash.isEmpty(rowData),
                    }"
                  >
                    <template v-if="cols.checkbox">
                      <v-checkbox
                        dense
                        :disabled="dataLoading"
                        v-model="selectedAll"
                        color="cyan"
                        class="hide-margin"
                        hide-details
                      ></v-checkbox>
                    </template>
                    <template v-else>
                      <div
                        v-if="cols.sortable"
                        class="simple-table-text sortable"
                        v-on:click="
                          !dataLoading
                            ? updateSorting({
                                field: cols.field,
                                sort: cols.sort,
                              })
                            : 'javascript:void(0)'
                        "
                      >
                        {{ cols.headerName }}
                      </div>
                      <div v-else class="simple-table-text">
                        {{ cols.headerName }}
                      </div>
                      <template v-if="cols.sort">
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'asc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-ascending</v-icon
                        >
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'desc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-descending</v-icon
                        >
                      </template>
                    </template>
                  </th>
                </template>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody class="custom-border-bottom">
              <template v-if="!lodash.isEmpty(rowData)">
                <tr
                  v-for="(data, index) in rowData"
                  :key="index"
                  link
                  :class="{ 'table-in-active-row': !data.activated }"
                >
                  <template v-for="cols in defaultColDefs">
                    <td
                      v-if="cols.visible"
                      :key="cols.id"
                      :class="{
                        'simple-table-td': !cols.checkbox,
                        'checkbox-width-limit': cols.checkbox,
                      }"
                      v-on:click="
                        !cols.checkbox ? rowClicked(data) : 'javascript:void(0)'
                      "
                    >
                      <template v-if="cols.checkbox">
                        <v-checkbox
                          dense
                          v-model="selectedRows"
                          v-bind:value="data.id"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else-if="cols.image">
                        <template v-if="data.channel == 'engineer'">
                          <v-img
                            class="customer-image white-background"
                            width="70"
                            height="70"
                            :lazy-src="$defaultProfileImage"
                            :src="getProfileImage(data.engineer_logo)"
                          >
                          </v-img>
                        </template>
                        <template v-else>
                          <v-img
                            class="customer-image white-background"
                            width="70"
                            height="70"
                            :lazy-src="$defaultProfileImage"
                            :src="getProfileImage(data.profile_logo)"
                          >
                          </v-img>
                        </template>
                      </template>
                      <template v-else-if="cols.field == 'user_name'">
                        <div class="user-listing-user">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Full Name: </b> {{ data.full_name }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Display Name: </b> {{ data.full_name }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Phone Number: </b> {{ data.phone_number }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Email: </b> {{ data.user_email }}
                          </p>
                          <!-- <p class="m-0 custom-nowrap-ellipsis">
                            <b>From Amount: </b> {{ data.from_amount }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>To Amount: </b> {{ data.to_amount }}
                          </p> -->
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'role'">
                        <div class="user-listing-role">
                          <p
                            class="m-0 custom-nowrap-ellipsis text-uppercase"
                            v-if="data.role"
                          >
                            <v-chip
                              class="ma-2"
                              color="deep-purple accent-4"
                              label
                              outlined
                            >
                              {{ data.role.name }}
                            </v-chip>
                          </p>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'added_at'">
                        <TableActivity
                          v-if="!lodash.isEmpty(data.added_by)"
                          :data="data"
                        >
                          <template v-slot:display_name>
                            {{ data.added_by.display_name }}
                          </template>
                          <template v-slot:date_time>
                            {{ data.created_at }}
                          </template>
                          <template v-slot:format_date_time>
                            {{ formatDateTime(data.added_at) }}
                          </template>
                        </TableActivity>
                      </template>
                      <template v-else-if="cols.field == 'updated_at'">
                        <TableActivity
                          v-if="!lodash.isEmpty(data.updated_by)"
                          :data="data"
                        >
                          <template v-slot:display_name>
                            {{ data.updated_by.display_name }}
                          </template>
                          <template v-slot:date_time>
                            {{ data.modified_at }}
                          </template>
                          <template v-slot:format_date_time>
                            {{ formatDateTime(data.updated_at) }}
                          </template>
                        </TableActivity>
                      </template>
                      <template v-else>
                        <div v-html="printRowData(cols, data)"></div>
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
              <tr v-else-if="!dataLoading">
                <td :colspan="columnLength()" class="py-4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no user at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </ListingTable>
      </perfect-scrollbar>
      <ListingFooter
        :dataLoading="dataLoading"
        :showingFrom="showingFrom"
        :showingTo="showingTo"
        :totalRows="totalRows"
        :currentPage="currentPage"
        :totalPages="totalPages"
      ></ListingFooter>
    </template>
  </ListingTemplate>
  <!--end::User Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, POST } from "@/core/services/store/request.module";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "user-list",
  data() {
    return {
      exportLoading: false,
      pageModule: "user-listing",
      routeAPI: "user",
      routeName: "user",
      routeDetailName: "user.detail",
      status: "all",
      file: "",
      importDialog: false,
      statusList: [],
      moreActions: [
        /*{
          title: "Import User(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        {
          title: "Export User(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },*/
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      userMoreAction: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          action: "inactive",
        },
      ],
    };
  },
  components: {
    draggable,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
  },
  methods: {
    getProfileImage(profile_logo) {
      if (profile_logo && profile_logo.file && profile_logo.file.url) {
        return profile_logo.file.url;
      }
      return null;
    },
    getBillingAddress(billing) {
      let billingArray = new Array();
      if (this.lodash.isEmpty(billing) === false) {
        if (billing.unit_no) {
          billingArray.push(billing.unit_no);
        }
        if (billing.street_1) {
          billingArray.push(billing.street_1);
        }
        if (billing.street_2) {
          billingArray.push(billing.street_2);
        }
        if (billing.zip_code) {
          billingArray.push(billing.zip_code);
        }
      }
      return billingArray.join(", ");
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkUserExport();
          break;
        case "export_current_view":
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    updateMoreAction(param) {
      const _this = this;
      switch (param) {
        case "active":
          _this.bulkUserUpdate({
            users: _this.selectedRows,
            status: 1,
          });
          break;
        case "inactive":
          _this.bulkUserUpdate({
            users: _this.selectedRows,
            status: 0,
          });
          break;
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    importRows() {
      // const _this = this;
      const file = this.$refs.file.files[0];
      if (!file) {
        alert("No file chosen");
        return;
      }
      const data = new FormData();
      data.append("excel", file);
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "user/import",
          data: data,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    bulkUserUpdate(requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "user/status",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
        });
    },
  },
  mounted() {
    new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "User",
      },
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: false,
        checkbox: true,
        order: 1,
      },
      {
        headerName: "Image",
        field: "image",
        sort: null,
        image: true,
        visible: true,
        fixed: false,
        sortable: false,
        order: 2,
      },
      {
        headerName: "User Info",
        field: "user_name",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
      },
      {
        headerName: "Role",
        field: "role",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 5,
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 6,
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: false,
        fixed: false,
        sortable: false,
        order: 7,
      },
    ];

    const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
      visible: true,
    });

    _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
